<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage parents meet</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Parents meet
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('parent-meet', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addParentsMeet">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Setup meeting
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="3">
                                    <v-text-field class="form-control" v-model="search.title"
                                        label="Search by title, unique identifier" outlined clearable
                                        v-on:keyup.enter="getParentMeets()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-text-field class="form-control" v-model="search.user"
                                              label="Search by user name, email or CRN" outlined clearable
                                              v-on:keyup.enter="getParentMeets()" dense>
                                    </v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select
                                        outlined
                                        dense
                                        @change="handleDateFilterTypeChange"
                                        v-model="search.date_filter_type"
                                        :items="dateFilterTypes"
                                        item-text="title"
                                        item-value="value"
                                        label="Select date type"
                                        clearable
                                        @input="search.date_filter_type = $event !== null ? $event : ''" 
                                    ></v-select>
                                </v-col>

                                <v-col cols="12" sm="12" md="3" v-if="search.date_filter_type == 'custom'">
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            min-width="auto"
                                            offset-y
                                            transition="scale-transition"
                                            v-model="menu"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                    append-icon="mdi-calendar"
                                                    clearable
                                                    dense
                                                    label="Date"
                                                    outlined
                                                    readonly 
                                                    v-bind="attrs"
                                                    v-model="search.date"
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                @input="menu = false"
                                                v-model="search.date"
                                            ></v-date-picker>
                                        </v-menu>
                                </v-col>
                              
                                <v-col cols="12" md="3">
                                    <v-select class="form-control" 
                                        v-model="search.status"
                                        label="Status" 
                                        outlined
                                        :items="statuses"
                                        item-text="title"
                                        item-value="value" 
                                        clearable
                                        v-on:keyup.enter="getParentMeets()" 
                                        dense
                                    >
                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        :items="academic_years" 
                                        dense 
                                        clearable
                                        item-text="title" 
                                        item-value="id"
                                        label="Batch/year"
                                        outlined
                                        v-model="search.academic_year_id">
                                    </v-select>
                                </v-col>
                            
                                <v-col cols="12" md="3">
                                    <v-select 
                                        :items="programs"
                                        @change="getGrades(search.program_id)" 
                                        dense
                                        clearable
                                        item-text="title"
                                        item-value="id"
                                        label="Program"
                                        outlined
                                        v-model="search.program_id">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        :items="gradesLevels" 
                                        @change="changeLevel" 
                                        dense 
                                        clearable
                                        item-text="title"
                                        item-value="id"
                                        label="Grade/semester"
                                        outlined
                                        v-model="search.grade_id">
                                    </v-select>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select 
                                        :items="academic_classes" 
                                        dense 
                                        item-text="title" 
                                        item-value="id"
                                        clearable
                                        label="Class"
                                        outlined
                                        v-model="search.class_id"
                                    ></v-select>
                                </v-col>
                                
                                <v-col cols="12" :md="search.date_filter_type == 'custom' ? 9 : 12"  class="text-right">
                                    <v-btn :loading="searching" @click.prevent="searchParentMeets()"
                                        class="btn btn-primary w-35" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="searching" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="searching" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!searching">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3 wrap-column">Student info</th>
                                        <th class="px-3 wrap-column">Meeting title</th>
                                        <th class="px-3 wrap-column">More info</th>
                                        <th class="px-3 wrap-column">Academic</th>
                                        <th class="px-3 wrap-column">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="parentMeets.length > 0" v-for="(item, index) of parentMeets" :key="index">
                                        <td class="px-3 wrap-column">
                                          <a href="#!" @click.prevent="studentSummary(item.user_id)" class="mb-1">
                                            {{ item.student_full_name ? item.student_full_name : 'NA' }}
                                          </a>|
                                          <a href="#!" @click.prevent="studentSummary(item)" class="mb-1">
                                            <b> {{ item.student_crn }} </b>
                                            <span class="badge-dot" v-bind:class="{ 'bg-color-green': item.is_active, 'bg-color-red': !item.is_active }">
                                            </span><br />
                                                              </a>
                                                              <span class="text-secondary" v-if="item.setting && item.setting.midas_id">
                                            <b> External ID:</b> {{ item.setting.midas_id != null ? item.setting.midas_id : 'N/A' }}
                                                                 |
                                                <strong>Class:</strong>
                                                {{ item.class_title ? item.class_title : 'NA' }} {{ item.class_unique_no }}

                                          </span>


                                        </td>
                                        <td class="px-3 wrap-column">
                                          <strong> {{ item.title }} </strong>
<!--                                            <div v-if="item.request_for_visit_reason" v-html="item.request_for_visit_reason"></div>-->
<!--                                            <div v-else>NA</div>-->
                                        </td>
                                        <td class="px-3 wrap-column">
                                            <div>
                                                {{ item.datetime_requested_to_visit_formatted ? item.datetime_requested_to_visit_formatted : ''}}
                                            </div>
                                            <div v-if="item.meeting_type">
                                                <small> <b>{{ item.meeting_type_text }}</b></small>

                                                <div v-if="item.meeting_type == 'online'">
                                                <small>
                                                    <b>Link:</b>
                                                    <a :href="item.online_meeting_link" target="_blank">
                                                    {{ item.online_meeting_link }}
                                                    </a>
                                                </small>
                                                </div>

                                                <div v-if="item.meeting_type == 'outside_premise'">
                                                <small>
                                                    <b>Link:</b> {{ item.outside_premise_location }}
                                                </small>
                                                </div>
                                            </div>

                                            <div class="d-flex">
                                                <div v-if="item.parent_comments" style="cursor:pointer;" title="Parent comments" class="mt-2 mb-2">
                                                    <span class="badge badge-success">
                                                        <i class="fa fa-comment text-white"></i>
                                                    </span>
                                                </div>
                                                <div v-if="item.attendee_comments" style="cursor:pointer;" title="Attendee comments" class="mt-2 mb-2">
                                                    <span class="badge badge-warning">
                                                        <i class="fa fa-comment text-white"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>

                                        <td class="px-3 wrap-column">
                                            <div class="mt-1">
                                                <strong>Program: </strong> {{ item.student_program ? item.student_program : 'NA' }}
                                            </div>
                                            <div class="mt-1">
                                                <strong>Grade: </strong> {{ item.student_grade ? item.student_grade : 'NA' }}
                                            </div>
                                            <div class="mt-1">
                                                <strong>Level: </strong> {{ item.student_level ? item.student_level : 'NA' }}
                                            </div>
                                            <div class="mt-1">
                                                <strong>Class: </strong> {{ item.student_class ? item.student_class : 'NA' }}
                                            </div>
                                            <div class="mt-1">
                                                <strong>Academic year: </strong> {{ item.student_academic_year ? item.student_academic_year : 'NA' }}
                                            </div>
                                        </td>
                                        
                                        
                                        <td class="px-3 pt-4 wrap-column">
                                            <span class="badge text-lg" :class="item.status =='not_attended' ? 'badge-danger':'badge-success'">
                                                {{ item.status_text }}
                                            </span>
                                            <div class="mt-2" v-if="item.status == 'not_attended'">
                                                <strong>Reason :</strong>
                                                <span v-html="item.not_attended_reason"></span>
                                            </div>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('parent-meet', 'edit')">
                                                        <a href="#" class="navi-link" @click="summary(item)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-eye"></i>
                                                            </span>
                                                            <span class="navi-text"> Summary </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="(item.status == 'requested' || item.status == 'not_attended') && checkIsAccessible('parent-meet', 'attended')" >
                                                        <a href="#" class="navi-link" @click="markAsAttended(item.id, item.class_id)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-check-circle"></i>
                                                            </span>
                                                            <span class="navi-text"> Mark as attended </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="(item.status == 'requested' || item.status == 'attended') && checkIsAccessible('parent-meet', 'not-attended')" >
                                                        <a href="#" class="navi-link" @click="markAsNotAttended(item.id, item.class_id)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-rectangle-xmark"></i>
                                                            </span>
                                                            <span class="navi-text"> Mark as not attended </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="item.status == 'attended' && checkIsAccessible('parent-meet', 'comment')" >
                                                        <a href="#" class="navi-link" @click="addParentComment(item.id, item.parent_comments)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-comment"></i>
                                                            </span>
                                                            <span class="navi-text"> Parent comments </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="item.status == 'attended' && checkIsAccessible('parent-meet', 'comment')" >
                                                        <a href="#" class="navi-link" @click="addAttendeeComment(item.id, item.attendee_comments)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-user"></i>
                                                            </span>
                                                            <span class="navi-text"> Attendee comments </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('parent-meet', 'generate-pdf')">
                                                        <a href="#" class="navi-link" @click="generatePdf(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="fa fa-file-pdf"></i>
                                                            </span>
                                                            <span class="navi-text"> Invitation letter </span>
                                                        </a>
                                                    </b-dropdown-text>

                                                    <!-- <b-dropdown-text tag="div" class="navi-item">
                                                        <a href="#" class="navi-link" @click="editParentsMeet(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text> -->
                                                    
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('parent-meet', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteParentMeets(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="parentMeets.length == 0">
                                        <td class="text-center  px-3" colspan="7">Data not available</td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="parentMeets.length > 0" 
                                @input="getParentMeets" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <mark-attended @refresh="getParentMeets" ref="mark-attended"></mark-attended>
        <mark-not-attended @refresh="getParentMeets" ref="mark-not-attended"></mark-not-attended>
        <parent-comment @refresh="getParentMeets" ref="parent-comment"></parent-comment>
        <attendee-comment @refresh="getParentMeets" ref="attendee-comment"></attendee-comment>
        <show @refresh="getParentMeets" ref="summary"></show>

    </v-app>
</template>

<script>
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";
import MarkAttended from "./MarkAttended";
import MarkNotAttended from "./MarkNotAttended";
import ParentComment from "./ParentComment.vue";
import AttendeeComment from "./AttendeeComment.vue";
import Show from "./Show.vue";
import {API_URL} from "@/core/config";
import FilterMixin from "@/mixins/FilterMixin";
import AcademicYearService from "@/core/services/academicYear/AcademicYearService";

const parentMeet = new StudentParentsVisitLogsService();
const academicYearService = new AcademicYearService();

export default {
    components:{
        MarkAttended, MarkNotAttended, ParentComment, AttendeeComment, Show
    },  
    mixins: [FilterMixin],
    data() {
        return {
            search: {
                title: '',
                status: '',
                date_filter_type: '',
                date: '',
                class: '',
                user: '',
                academic_year_id: '',
                program_id: '',
                grade_id: '',
                class_id: ''
            },
            menu: false,
            searching: false,
            parentMeets: [],
            page: null,
            perPage: null,
            total: null,
            dateFilterTypes:[
                {
                    title: 'Today',
                    value: 'today'
                },
                {
                    title: 'Upcoming',
                    value: 'upcoming'
                },
                {
                    title: 'Previous',
                    value: 'past'
                },
                {
                    title: 'Custom',
                    value: 'custom'
                },
            ],
            statuses:[
                {
                    title: 'Requested',
                    value: 'requested'
                },
                {
                    title: 'Attended',
                    value: 'attended'
                },
                {
                    title: 'Not Attended',
                    value: 'not_attended'
                }
            ],
            academic_years: [],
        }
    },
    methods: {
        searchParentMeets(){
            this.page = 1;
            this.getParentMeets();
        },  
        getAcademicYears() {
            academicYearService.all().then(response => {
                this.academic_years = response.data;
            });
        },
        getParentMeets() {
            this.searching = true;
            parentMeet
                .paginate(this.search, this.page)
                .then(response => {
                    this.parentMeets = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.searching = false;
                })
                .catch(error => {
                    this.searching = false;
                });
        },
        addParentsMeet() {
            this.$router.push({
                name: 'create-student-parents-visit-logs'
            });
        },
        editParentsMeet(id) {
        },
        deleteParentMeets(parentMeetId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        parentMeet
                        .delete(parentMeetId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getParentMeets();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        studentSummary(student_id){
            if(this.checkIsAccessible('user', 'list')) {
              this.$router.push( {
                name: 'students-summary',
                params: {id: student_id}
              });
            }
        },
        markAsAttended(id, classId){
            this.$refs["mark-attended"].showModal(id, classId);
        },
        markAsNotAttended(id, classId){
            this.$refs["mark-not-attended"].showModal(id, classId);
        },
        addParentComment(id, parentComments){
            this.$refs["parent-comment"].showModal(id, parentComments);
        },
        addAttendeeComment(id, attendeeComments){
            this.$refs["attendee-comment"].showModal(id, attendeeComments);
        },
        handleDateFilterTypeChange(){
            if(this.search.date_filter_type == 'custom'){
                this.search.date = null;
            }
        },
        generatePdf(id){
            window.open(
                API_URL + "user/student-parents-visit-log/" + id + '/pdf/preview',
                "_blank"
            );
        },
        summary(item){
            this.$refs["summary"].showModal(item);
        },
        changeLevel(){
            this.getAcademicClasses(this.search.academic_year_id,this.search.program_id,this.search.grade_id);
        },
    },
    mounted() {
        this.getParentMeets();
        this.getAllPrograms();
        this.getAcademicYears();
    }
}
</script>

<style scoped>
    .wrap-column{
        max-width: 270px !important; 
        white-space: pre-wrap;
    }
</style>