<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Mark meeting as attended
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                          <h6>When did the parent attended this meeting?</h6>
                            <v-datetime-picker
                                outlined
                                dense
                                ref="dateTime"
                                label="Datetime of visit"
                                :text-field-props="textFieldProps"
                                v-model="studentParentsVisitLog.datetime_of_visit"
                            >
                            </v-datetime-picker>
                            <span
                                class="text-danger"
                                v-if="$v.studentParentsVisitLog.datetime_of_visit.$error"
                            >This information is required</span>
                        </v-col>

                        <v-col cols="12" md="12" class="mt-3">
                          <h6>Select management team member who attended the meeting with the parent</h6>
                            <v-autocomplete
                                @change="selectAttendedByUser"
                                v-model="selectedAttendedByUser"
                                outlined
                                dense
                                :items="attendedByUsers"
                                hide-selected
                                item-text="display_text"
                                item-value="id"
                                :search-input.sync="attendedByUserSearch"
                                :loading="isAttendedByUserLoading"
                                clearable
                                placeholder="Search by user's name, email or CRN"
                            >
                            <template v-slot:label>
                                Select staff member<span class="text-danger"></span>
                            </template>
                            <template v-slot:no-data>
                                <div class="no-data-auto-complete text-center">No data available </div>
                            </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.studentParentsVisitLog.attended_by.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" v-if="classId">
                            <v-checkbox
                                v-model="studentParentsVisitLog.mark_whole_class"
                                label="Was this meeting as a group for all parents in same room and meeting was attended for all students?"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>

                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="save"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";
import UserService from "@/core/services/user/UserService";

const visitLog = new StudentParentsVisitLogsService();
const user = new UserService();

export default {
    validations: {
        studentParentsVisitLog:{
            datetime_of_visit: {required},
            attended_by: {required}
        }
    },
    data() {
        return {
            studentParentsVisitLog:{
                datetime_of_visit: null,
                attended_by: '',
                mark_whole_class: false,
            },
            dialog: false,
            isBusy: false,
            editorConfig: {
                versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            errors: [],
            studentParentsVisitLogId: '',
            textFieldProps: {
                outlined: true,
                dense: true,
            },
            isAttendedByUserLoading: false,
            attendedByUsers: [],
            attendedByUserSearch: "",
            classId: null,
        };
    },
    methods: {
        showModal(id = null, classId=null) {
            this.studentParentsVisitLogId = id;
            this.classId = classId;
            this.dialog = true;
            this.setCurrentDateTime();
        },
        hideModal() {
            this.dialog = false;
        },
        save() {
            this.$v.studentParentsVisitLog.$touch();
            if (this.$v.studentParentsVisitLog.$error) {
                setTimeout(() => {
                    this.$v.studentParentsVisitLog.$reset();
                }, 3000);
            } else {
                this.markAsAttended();
            }
        },
        markAsAttended() {
            this.isBusy = true;

            this.formatVisitDateTime();

            visitLog
            .markAsAttended(this.studentParentsVisitLogId, this.studentParentsVisitLog)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        formatVisitDateTime() {
            if (this.studentParentsVisitLog.datetime_of_visit) {
                var fullDate = new Date(this.studentParentsVisitLog.datetime_of_visit);
                const year = fullDate.getFullYear();
                const month = parseInt(fullDate.getMonth()) + parseInt(1);
                const day = fullDate.getDate();
                const hour = fullDate.getHours();
                const minute = fullDate.getMinutes();

                const entryDateTime =
                year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + "00";
                this.studentParentsVisitLog.datetime_of_visit = null;
                this.studentParentsVisitLog.datetime_of_visit = entryDateTime;
            }
            return;
        },
        selectAttendedByUser() {
            if (this.selectedAttendedByUser && this.selectedAttendedByUser != null) {
                this.studentParentsVisitLog.attended_by = this.selectedAttendedByUser;
            } else {
                this.studentParentsVisitLog.attended_by = "";
            }
        },
        selectedAttendedByUser(val) {
            if (!val) {
                this.studentParentsVisitLog.attended_by = "";
            }
        },
        resetForm() {
            this.$refs.dateTime.date = '';

            this.$v.studentParentsVisitLog.$reset();

            this.studentParentsVisitLog = {
                datetime_of_visit: '',
                attended_by: '',
                mark_whole_class: false,
            };
            this.isBusy = false;

            this.errors = [];
            this.studentParentsVisitLogId= '',
            this.attendedByUsers = [];
            this.attendedByUserSearch = "";
            this.classId = null;

            this.hideModal();
            this.$emit("refresh");
        },
        setCurrentDateTime(){
            var fullDate = new Date();
            this.studentParentsVisitLog.datetime_of_visit = fullDate;
        }
    },
    watch: {
        attendedByUserSearch(val) {
        this.isAttendedByUserLoading = true;

        const types = ["admin", "teacher", "staff"];

        let data = {
            val: val,
            types: types,
        };

            user
            .getByTypes(data)
            .then((response) => {
            response.data.users.map((user) => {
                let data = user;
                data.display_text =
                user.full_name + " | " + user.personal_id + " | " + user.email;
                this.attendedByUsers.push(data);
            });
            })
            .catch((err) => {})
            .finally(() => (this.isAttendedByUserLoading = false));
        },
    }

}
</script>
