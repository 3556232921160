<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Mark meeting as not attended
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="resetForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <h6>
                                Reason for not attending  <span class="text-danger"></span>
                            </h6>
                            <ckeditor
                                v-model="studentParentsVisitLog.not_attended_reason"
                                :config="editorConfig"
                            ></ckeditor>
                            <span class="text-danger" v-if="$v.studentParentsVisitLog.not_attended_reason.$error">This information is required</span>
                            <div class="text-danger" v-if="errors.not_attended_reason">{{ errors.not_attended_reason[0] }}</div>
                        </v-col>

                        <v-col cols="12" v-if="classId">
                            <v-checkbox
                                v-model="studentParentsVisitLog.mark_whole_class"
                                label="Was this meeting as a group for all parents in same room and meeting was attended for all students?"
                            ></v-checkbox>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="resetForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>

                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="save"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";

const visitLog = new StudentParentsVisitLogsService();

export default {
    validations: {
        studentParentsVisitLog:{
            not_attended_reason: ''
        }
    },
    data() {
        return {
            studentParentsVisitLog:{
                not_attended_reason: '',
                mark_whole_class: false,
            },
            dialog: false,
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            errors: [],
            classId: null,
        };
    },
    methods: {
        showModal(id = null, classId=null) {
            this.studentParentsVisitLogId = id;
            this.classId = classId;
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        save() {
            this.$v.studentParentsVisitLog.$touch();
            if (this.$v.studentParentsVisitLog.$error) {
                setTimeout(() => {
                    this.$v.studentParentsVisitLog.$reset();
                }, 3000);
            } else {
                this.markAsNotAttended();
            }
        },
        markAsNotAttended() {
            this.isBusy = true;

            visitLog
            .markAsNotAttended(this.studentParentsVisitLogId, this.studentParentsVisitLog)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.studentParentsVisitLog.$reset();
            this.studentParentsVisitLog = {
                not_attended_reason: '',
                mark_whole_class: false,
            };
            this.hideModal();
            this.$emit("refresh");
        }
    }
}
</script>
