<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Parent comments
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12">
                            <ckeditor
                                v-model="studentParentsVisitLog.parent_comments"
                                :config="editorConfig"
                            ></ckeditor>
                            <span class="text-danger" v-if="$v.studentParentsVisitLog.parent_comments.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="hideModal"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>

                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="save"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import StudentParentsVisitLogsService from "@/core/services/student-parents-visit-logs/StudentParentsVisitLogsService";

const visitLog = new StudentParentsVisitLogsService();

export default {
    validations: {
        studentParentsVisitLog:{
            parent_comments: {required},
        }
    },
    data() {
        return {
            studentParentsVisitLog:{
                parent_comments: ''
            },
            dialog: false,
            isBusy: false,
            editorConfig: {
              versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
            studentParentsVisitLogId: '',
        };
    },
    methods: {
        showModal(id = null, parentComments=null) {
            this.resetForm();
            this.studentParentsVisitLogId = id;
            this.studentParentsVisitLog.parent_comments = parentComments;
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        save() {
            this.$v.studentParentsVisitLog.$touch();
            if (this.$v.studentParentsVisitLog.$error) {
                setTimeout(() => {
                    this.$v.studentParentsVisitLog.$reset();
                }, 3000);
            } else {
                this.update();
            }
        },
        update() {
            this.isBusy = true;

            visitLog
            .update(this.studentParentsVisitLogId, this.studentParentsVisitLog)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Comment added");
                this.hideModal();
                this.$emit('refresh');
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.studentParentsVisitLog.$reset();
            this.studentParentsVisitLog = {
                parent_comments: '',
            };
        }
    }
}
</script>
