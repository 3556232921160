<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        Parents meet summary
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="hideModal">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>
                
                <v-card-text>
                    <v-row>
                        <v-col cols="12" md="12" class="ml-2">
                            <div>
                                <h6>
                                    <strong> Title: </strong> {{ parentsMeet.title }}
                                </h6>
                            </div>

                            <div class="mt-4">
                                <h6>
                                    <strong>Class: </strong> {{ parentsMeet.class_title ? parentsMeet.class_title : 'NA' }}
                                </h6>
                            </div>
                            

                            <div class="d-flex justify-space-between">
                                <h6 class="mt-3">
                                    <strong>Meeting type: </strong> {{ parentsMeet.meeting_type_text ? parentsMeet.meeting_type_text : 'NA'}}
                                </h6>
                            </div>

                            <div>
                                <h6 class="mt-3" v-if="parentsMeet.meeting_type == 'outside_premise'">
                                    <strong>Location:</strong> {{ parentsMeet.outside_premise_location }}
                                </h6>
                                <h6 class="mt-3" v-if="parentsMeet.meeting_type == 'online'">
                                    <strong>Meeting link: </strong>
                                    <a target="_blank" :href=parentsMeet.online_meeting_link>
                                        {{ parentsMeet.online_meeting_link }}
                                    </a>
                                </h6>
                            </div>
                            
                            
                            <div class="d-flex justify-space-between">
                                <h6 class="mt-3">
                                    <strong>Requested date: </strong> {{ parentsMeet.datetime_requested_to_visit_formatted ? parentsMeet.datetime_requested_to_visit_formatted : 'NA'}}
                                </h6>
                            </div>

                            <h6 class="mt-3 border p-3">
                                <strong>Visit request reason: </strong> 
                                <div class="mt-2" v-html="parentsMeet.request_for_visit_reason"></div>
                            </h6>

                            <div class="d-flex">
                                <h6 class="mt-3">
                                    <strong>Status: </strong> {{ parentsMeet.status_text ? parentsMeet.status_text : 'NA'}}
                                </h6>
                            </div>

                            <h6 class="mt-3" v-if="parentsMeet.status == 'attended'">
                                <strong>Date of visit: </strong>
                                 {{ parentsMeet.datetime_of_visit_formatted }}
                            </h6>

                            <h6 class="mt-3 border p-3" v-if="parentsMeet.status == 'attended'">
                                <strong>Parent comments: </strong> 
                                <div class="mt-2" v-if="parentsMeet.parent_comments" v-html="parentsMeet.parent_comments"></div>
                                <div v-else>NA</div>
                            </h6>

                            <h6 class="mt-3 border p-3" v-if="parentsMeet.status == 'attended'">
                                <strong>Attendee comments: </strong> 
                                <div class="mt-2" v-if="parentsMeet.attendee_comments" v-html="parentsMeet.attendee_comments"></div>
                                <div v-else>NA</div>
                            </h6>

                            <h6 class="mt-3 border p-3" v-if="parentsMeet.status == 'not_attended'">
                                <strong>Reason: </strong> 
                                <div class="mt-2" v-if="parentsMeet.not_attended_reason" v-html="parentsMeet.not_attended_reason"></div>
                                <div v-else>NA</div>
                            </h6>

                        </v-col>
                        
                        <h4 class="mt-4"><strong>Student Info</strong></h4>

                        <v-col cols="12" md="6">
                            <h6 class="mt-1">
                                <strong>Name: </strong> {{ parentsMeet.student_full_name }}
                            </h6>
                            <h6 class="mt-1">
                                <strong>Email: </strong> {{ parentsMeet.student_email }}
                            </h6>
                            <h6 class="mt-1">
                                <strong>CRN: </strong> {{ parentsMeet.student_crn }}
                            </h6>
                            <h6 class="mt-1">
                                <strong>Program: </strong> {{ parentsMeet.student_program ? parentsMeet.student_program : 'NA' }}
                            </h6>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                            <h6 class="mt-1">
                                <strong>Grade: </strong> {{ parentsMeet.student_grade ? parentsMeet.student_grade : 'NA' }}
                            </h6>
                            
                            <h6 class="mt-1">
                                <strong>Level: </strong> {{ parentsMeet.student_level ? parentsMeet.student_level : 'NA' }}
                            </h6>

                            <h6 class="mt-1">
                                <strong>Class: </strong> {{ parentsMeet.student_class ? parentsMeet.student_class : 'NA' }}
                            </h6>
                        </v-col>

                        <h4 class="mt-4" v-if="parentsMeet.parents_info"><strong>Parent Info</strong></h4>
                        <v-col cols="12" v-if="parentsMeet.parents_info" v-for="item in parentsMeet.parents_info">
                            <div>
                                <h6 class="mt-1">
                                    <strong>Name: </strong> {{ item.name }}
                                </h6>

                                <h6 class="mt-1">
                                    <strong>Email: </strong> {{ item.email }}
                                </h6>

                                <h6 class="mt-1">
                                    <strong>Mobile: </strong> {{ item.mobile ? item.mobile : 'NA' }}
                                </h6>
                            </div>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn 
                                    depressed 
                                    @click="hideModal" 
                                    class="text-gray btn btn-standard
                                ">
                                    Close
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
export default {
    data() {
        return {
            parentsMeet: '',
            dialog: false
        };
    },
    methods: {
        showModal(parentsMeet) {
            this.parentsMeet = parentsMeet;
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        }
    }
}
</script>
